import React from 'react';

export const SectionContainer = ({
  children,
  id = undefined,
  className = 'relative',
  background = undefined,
  maxWidth = 960,
}) => (
  <section id={id} className={className} style={{ background }}>
    <div className="px-6 py-6 mx-auto sm:px-24 sm:py-8" style={{ maxWidth }}>
      {children}
    </div>
  </section>
);
