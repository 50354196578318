import React from 'react';
import { Layout } from '../layouts/Layout';
import { SEO } from '../components/SEO';
import { UnderConstruction } from '../components/UnderConstruction';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <UnderConstruction />
  </Layout>
);

export default NotFoundPage;
